<template>
    <div></div>
</template>

<script>
import router from '@/router'
import Util from '@/helpers/utilities.js'

export default {
    methods: {
        logout() {
            this.$store.commit('SET_LOADER_TEXT', 'Odjava uporabnika je v teku...')
            this.$store.commit('SET_LOADER', true)

            this.$store.dispatch('LOGOUT')
            .then(response => {

                setTimeout(() => { 
                    this.$store.commit('SET_LOADER', false) 
                    router.push({
                        name: 'login'
                    })
                }, 800)
            })
            .catch(error =>{
                setTimeout(() => { this.$store.commit('SET_LOADER', false) }, 800),
                window.console.log('Logout error')
                window.console.log(error)

            })
        }
    },

    created() {
        this.logout()
    }
}

</script>